<template>
  <div :class="this.cssClass" class="form-group position-relative d-flex mb-2" :id="id">
    <input :class="{ 'has-value': modelValue }" :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)" type="date" class="form-control birth-date"
      placeholder="Sığorta məbləği" :max="maxDate" />
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
export default {
  props: ["modelValue", "cssClass"],
  validations() {
    return {
      modelValue: { required }
    }
  },
  data: () => ({
    value: null
  }),
  mounted() {
    this.value = this.modelValue;
  },
  setup: () => ({ v$: useVuelidate() }),
  computed: {
    maxDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
  },
}
</script>
