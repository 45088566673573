<template>
    <div>
        <div class="pb-5">
            <p class="h4 payment-initial-form-text">
                Şəhadətnamənizin buraxılması üçün məlumatlarının doğruluğunu yoxlayın və təstiq edin.
            </p>
        </div>
        <div v-if="data" class="row">
            <div class="check-info-body">
                <div class="check-info-body-content">
                    <div>
                        <div class="check-info-body-content-head">Sığorta müqaviləsi barədə məlumat</div>
                        <div class="row check-info-content-body">
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                                <div class="check-info-content-body-head">Sığorta şirkəti</div>
                                <div class="check-info-content-body-text">
                                    Qala Sığorta
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                                <div class="check-info-content-body-head">Sığorta təminatı</div>
                                <div class="check-info-content-body-text">
                                    Standart
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                                <div class="check-info-content-body-head">Sığorta haqqı</div>
                                <div class="check-info-content-body-text">
                                    {{ premium }} AZN
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="check-info-body-content">
                    <div>
                        <div class="check-info-body-content-head">Səfər barədə məlumat</div>
                        <div class="row check-info-content-body">
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                                <div class="check-info-content-body-head">Səfər edilən ölkə</div>
                                <div class="check-info-content-body-text">
                                    {{ data.country }}
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                                <div class="check-info-content-body-head">Səfərin məqsədi</div>
                                <div class="check-info-content-body-text">
                                    Turizm
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                                <div class="check-info-content-body-head">Sığorta məbləği</div>
                                <div class="check-info-content-body-text">
                                    {{ data.amount }} {{ data.currency }}
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                                <div class="check-info-content-body-head">Səfərin müddəti</div>
                                <div class="check-info-content-body-text">
                                    {{ dateTime(data.startDate) }}
                                </div>
                                <div class="check-info-content-body-text">
                                    {{ dateTime(data.endDate) }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div  class="check-info-body-content">
                    <div>
                        <div class="check-info-body-content-head">Sığortalılar barədə məlumat</div>
                        <div v-for="(data,index) in insuredPersons" :key="index"  class="row check-info-content-body pb-0">
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                                <div class="check-info-content-body-head">Ad, soyad</div>
                                <div class="check-info-content-body-text">
                                    <span>{{ index+1 }}.</span> {{ data.firstName }} {{ data.firstName }}
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                                <div class="check-info-content-body-head">Pasport No</div>
                                <div class="check-info-content-body-text">
                                    {{ data.passportNumber }}
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                                <div class="check-info-content-body-head">Doğum tarixi</div>
                                <div class="check-info-content-body-text">
                                    {{ dateTime(data.birthDate) }}
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                                <div class="check-info-content-body-head">Sığorta haqqı</div>
                                <div class="check-info-content-body-text">
                                    {{ data.premium }} AZN
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="d-flex justify-content-end pe-0">
                        <a @click="back" class="btn  btn-default btn-hover d-inline-block text-decoration-none">
                            Geri
                        </a>
                        <button @click="submit" type="submit"
                            class="btn-main btn-default btn-hover cursor-pointer d-inline-block ms-3" 
                            :disabled="loading">
                            <span class="spinner-border spinner-border-sm" v-if="loading" />
                            Təsdiqlə
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import moment from "moment";
export default {
    props: ["loading","data","premium"],
    data: () => ({
      insuredPersons: [],
    }),
    methods: {
        back() {
            this.$emit("back");
        },
        submit() {
            this.$emit("submit");
        },
        setData(value) {
          this.insuredPersons=value;
        },
        dateTime(value) {
            return moment(value).format("DD.MM.YYYY");
        }
    },
};
</script>