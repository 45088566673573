<template>
    <div v-if="loading" class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <div v-if="!loading">
        <div class="pb-5">
            <p class="h4 initial-form-text">
                Sığorta haqqını qəbul edirsinizsə sığortanın alınmasına keçin.
            </p>
        </div>

        <div v-if="data" class="row">
            <div class="check-info-body">
                <div class="check-info-body-content">
                    <div>
                        <div class="row check-info-content-body">
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 py-2">
                                <div class="check-info-content-body-head">Səfərin məqsədi</div>
                                <div v-for="p in purpose" :key="p.key" class="check-info-content-body-text">
                                    <div v-if="p.key == data.purpose">
                                        {{ p.value }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 py-2">
                                <div class="check-info-content-body-head">Səfər edilən ölkə</div>
                                <div class="check-info-content-body-text">
                                    {{ data.country }}
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 py-2">
                                <div class="check-info-content-body-head">Səfərin müddəti</div>
                                <div class="check-info-content-body-text">
                                    {{ dateTime(startDate) }} <br> {{ dateTime(data.endDate) }}
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 py-2">
                                <div class="check-info-content-body-head">Sığorta məbləği</div>
                                <div class="check-info-content-body-text">
                                    {{ data.amount }} {{ data.currency }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mb-5">
                    <div class="calc-info-text">Sığorta olunanların doğum tarixləri</div>
                    <div v-for="(d, index) in data.insuredPersons" :key="index" class="col-lg-6">
                        <div class="calc-info my-2">
                            {{ index + 1 }}. <span>{{ dateTime(d.birthDate) }}</span>
                        </div>
                    </div>
                </div>

                <div class="calc-result d-flex align-items-center">
                    <span class="calc-text me-2">
                        Cəmi:
                    </span>
                    <div class="calc-number">
                        <span>{{ premium }} AZN</span>
                    </div>
                </div>

                <div class="calc-result-text-button row mt-5">
                    <div class="calc-info-text col-xl-7 col-lg-12 mb-0">Sığorta təminatları “Qala Sığorta” ASC tərəfindən təqdim olunur.</div>
                    <div class="pe-0 col-xl-5 col-lg-12 d-flex justify-content-end">
                        <a @click="back" class="btn  btn-default btn-hover d-inline-block text-decoration-none">
                            Geri
                        </a>
                        <button @click="submit" type="submit"
                            class="btn-main btn-default btn-hover cursor-pointer d-inline-block ms-3" :disabled="loading">
                            <span class="spinner-border spinner-border-sm" v-if="loading" />
                            Sığorta al
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { Purpose } from "@/enums";
import moment from "moment";
export default {
    props: ["loading", "data", "premium"],
    methods: {
        back() {
            this.$emit("back");
        },
        submit() {
            this.$emit("buyIns");
        },
        dateTime(value) {
            return moment(value).format("DD.MM.YYYY");
        }
    },
    computed: {
        purpose() { return Purpose },
        startDate() {
            const today = this.data.startDate;
            return today.toLocaleString("en-GB");
        },
    }
};
</script>