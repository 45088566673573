<template>
  <div class="row">
    <p class="h4 initial-form-text">
      Şəhadətnamənin buraxılması üçün sığorta olunanlar barədə məlumatları daxil edin.
    </p>
    <form @submit.prevent="submitHandler">
      <div class="row">
        <div v-for="(p, index) in form.insuredPersons" :key="index" class="col-lg-6 mt-5">
          <p>{{ index + 1 }}. Sığorta alan </p>
          <div class="form-group position-relative">
            <input disabled class="form-control login-input" :value="dateTime(form.insuredPersons[index].birthDate)" />
          </div>
          <div class="form-group position-relative mt-4" :class="{error: v$.form.insuredPersons.$each.$response.$data[index].firstName.$invalid}" >
            <input type="text" class="form-control login-input" v-model="form.insuredPersons[index].firstName"
              placeholder="Ad (pasportda olduğu kimi)" />
          </div>
          <div class="form-group position-relative mt-4" :class="{error: v$.form.insuredPersons.$each.$response.$data[index].lastName.$invalid}">
            <input type="text" class="form-control login-input" v-model="form.insuredPersons[index].lastName"
              placeholder="Soyad (pasportda olduğu kimi)" />
          </div>
          <div class="form-group position-relative mt-4" :class="{error: v$.form.insuredPersons.$each.$response.$data[index].passportNumber.$invalid}">
            <input type="text" class="form-control login-input" v-model="form.insuredPersons[index].passportNumber"
              placeholder="Xarici pasport nömrəsi" />
          </div>
          <div v-if="index==0" class="form-group position-relative mt-4" :class="{ error: v$.form.phoneNumber.$invalid }">
            <input v-model="form.phoneNumber" v-maska="'+994## ###-##-##'" placeholder="Telefon nömrəsi" type="text"
              class="form-control login-input" />
          </div>
        </div>
        <!-- <div class="container">
          <div class="col-lg-6">
          <div class="form-group mt-4 position-relative" :class="{ error: v$.form.phoneNumber.$invalid }">
            <input v-model="form.phoneNumber" v-maska="'+994## ###-##-##'" placeholder="Telefon nomresi" type="text"
              class="form-control login-input" />
          </div>
        </div>
        </div> -->
      </div>
      <div class="row mt-5">
        <div class="col d-flex justify-content-end">
          <a @click="back" class="btn  btn-default btn-hover d-inline-block text-decoration-none">
            Geri
          </a>
          <button type="submit" class="btn-main btn-default btn-hover cursor-pointer d-inline-block ms-3">
            Müqaviləni burax
          </button>
        </div>
      </div>
    </form>
  </div>
</template>


<script>
import { maska } from "maska";
import { helpers, required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import moment from "moment";

const initialValue = {
  insuredPersons: [],
  phoneNumber: null,
};


export default {
  directives: { maska },
  data: () => ({
    form: { ...initialValue },
  }),
  validations() {
    return {
      form: {
        phoneNumber: { required },
        insuredPersons: {
          $each: helpers.forEach({
            firstName:{required},
            lastName:{required},
            passportNumber:{required}
          })
        },
      }
    }
  },
  setup: () => ({ v$: useVuelidate() }),
  
  methods: {
    async submitHandler() {
      const result = await this.v$.$validate();
      if (result) {
        this.$emit("confirm", this.form);
      }
    },
    setData(value) {
      this.form.insuredPersons = value;
      this.v$.$reset();
    },
    back() {
      this.$emit("back");
    },
    dateTime(value) {
      return moment(value).format("DD/MM/YYYY");
    }
  }
};
</script>
 