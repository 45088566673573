<template>
  <section id="advantages">

    <div id="ins-advantages">
      <div class="container">
        <div class="row rounded advantages m-0">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-5">
            <p class="h2 text-main fw-bolder text-center">
              <span class="text-blue">Mobins</span>
              ilə səfər sığortasının üstünlükləri
            </p>
          </div>
          <div class="
                col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12
                pb-5
                mt-5
                mx-auto
              ">
            <div class="row justify-content-around advantages-row">
              <div class="col-lg-4 col-xl-3 col-md-12 col-sm-12 col-xs-12 ">
                <div class="home-advantages-item">
                  <div class="text-center mx-auto d-flex align-items-center">
                    <img src="@/assets/images/ins-advantage1.svg" class="mx-auto" />
                  </div>
                  <p class="h6 pt-3">
                    Sığorta şəhadətnaməsini heç yerə getmədən rahat, onlayn şəkildə əldə edirsiniz.
                  </p>
                </div>
              </div>
              <div class="col-lg-4 col-xl-3 col-md-12 col-sm-12 col-xs-12">
                <div class="home-advantages-item">
                  <div class="text-center mx-auto d-flex align-items-center">
                    <img src="@/assets/images/ins-advantage2.svg" class="mx-auto" />
                  </div>
                  <p class="h6 pt-3">
                    Sığortanı bir başa əldə etməkdən öncə qiymət və şərtlərin düzgün olmasına əmin ola bilirsiz.
                  </p>
                </div>
              </div>
              <div class="col-lg-4 col-xl-3 col-md-12 col-sm-12 col-xs-12">
                <div class="home-advantages-item">
                  <div class="text-center mx-auto d-flex align-items-center">
                    <img src="@/assets/images/advantages-3.svg" class="mx-auto" />
                  </div>
                  <p class="h6 pt-3">
                    24/7 rejimində sualları cavablandırmağa hazır müştəri xidmətindən faydalanmaq imkanı.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container overflow-hidden position-relative mt-5">
      <div class="row rounded">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-5">
          <p class="h2 text-main fw-bolder text-center pt-2">
            SƏFƏR sığortasını  <br> almaq üçün <span class="text-blue">3 addım </span>
          </p>
        </div>
        <div class="
                  col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12
                  pb-5
                  mt-5
                  mx-auto
                ">
          <div class="row justify-content-center  position-relative">
            <img src="@/assets/images/ins-arrow.svg" class="ins-arrow-bg" />
            <div class="row">
              <div class="
                      col-lg-4 col-xl-4 col-md-12 col-sm-12 col-xs-12
                      how-to-get-ins
                    ">
                <div class=" bg-white mx-auto d-flex align-items-center">
                  <img src="@/assets/images/ins-test.svg" />
                </div>
                <p class="h6 how-to-get-ins-text pt-3">1. Məlumatlarınızı daxil edin.</p>
                <p class="how-to-get-ins-body">
                  Bu məlumatlar tələblərinizə uyğun sığorta haqqının düzgün hesablanması üçün lazımdır.
                </p>
              </div>
              <div class="
                      col-lg-4 col-xl-4 col-md-12 col-sm-12 col-xs-12
                      how-to-get-ins
                    ">
                <div class=" bg-white mx-auto d-flex align-items-center">
                  <img src="@/assets/images/ins-notes.svg" />
                </div>
                <p class="h6 how-to-get-ins-text pt-3">
                  2. Təstiq edin.
                </p>
                <p class="how-to-get-ins-body">
                  Məlumatların doğruluğunu birdə yoxlayın, təklif olunan şərtlər ilə tanış olun, təklifimiz Sizə uyğundursa təstiq edin.
                </p>
              </div>
              <div class="
                      col-lg-4 col-xl-4 col-md-12 col-sm-12 col-xs-12
                      how-to-get-ins
                    ">
                <div class=" bg-white mx-auto d-flex align-items-center">
                  <img src="@/assets/images/ins-payment.svg" />
                </div>
                <p class="h6 how-to-get-ins-text pt-3">3. Ödəniş edin və sığorta şəhadətnaməsini alın.</p>
                <p class="how-to-get-ins-body">
                  Təstiq edilən kimi dərhal sığorta şəhadətnaməsi buraxılır, ödəniş edib şəhadətnaməni aktivləşdirin. Ödəniş, terminallar vasitəsilə nağd və ya bank kartı ilə onlayn edə bilərsiz.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container overflow-hidden position-relative mt-5">
      <img src="@/assets/images/white-question.svg" alt="question" class="ins-faq-question-img" />
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-5 mb-4">
          <p class="h2 text-main fw-bolder text-center pt-2">
            Səfər sığortası ilə bağlı <br> ən çox verilən
            <span class="text-blue">suallar</span>
          </p>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                  aria-expanded="true" aria-controls="collapseOne">
                  <span class="h5 text-main fw-bold ins-tab-item-text">
                    Səfər sığortası nədir?
                  </span>
                </button>
              </h2>
              <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <div class="accordion-body-text">
                    Səfər sığortası – xaricə gedən insanın öz həyat və sağlamlığını sığortaladığı xüsusi sığorta
                    proqramıdır.
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  <span class="h5 fw-bold ins-tab-item-text">
                    Səfərdə olarkən hadisə baş versə nə etməliyəm?
                  </span>
                </button>
              </h2>
              <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <div class="accordion-body-text">
                    Sığorta hadisəsinin baş verməsi barədə sığortalı və ya sığorta olunan şəxs, yaxud faydalanan şəxs
                    hadisədən
                    xəbər tutduqdan dərhal sonra və ya mümkün olan ən qısa müddət ərzində sığortaçıya və ya onun
                    nümayəndəsinə,
                    eyni zamanda həmin hadisə barədə Dövlət Yol Polisinə hər hansı vasitə ilə xəbər verməlidi.
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingFour">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                  <span class="h5 ins-tab-item-text">
                    Səfər sığortası üzrə xərclər necə qarşılanır?
                  </span>
                </button>
              </h2>
              <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                data-bs-parent="#accordionExample">

                <div class="accordion-body">
                  <div class="accordion-body-text">
                    - Sığorta hadisəsinin baş verməsi barədə sığortalı və ya sığorta olunan şəxs, yaxud faydalanan şəxs
                    hadisədən xəbər tutduqdan dərhal sonra və ya mümkün olan ən qısa müddət ərzində xəbər verməməsi
                    nəticəsində sığortaçının hadisənin sığorta hadisəsi olub-olmamasını müəyyənləşdirmək imkanından məhrum
                    olması;
                    <br />
                    <br />
                    - Zərərçəkənin müvafiq hadisənin baş verməsinə yönələn qəsdən etdiyi hərəkəti və ya hərəkətsizliyi,
                    habelə hadisə ilə birbaşa səbəb əlaqəsində olan qəsdən cinayət törətməsi;
                    <br />
                    <br />
                    - Müqavilə və ya icbari sığorta qanunları ilə hərbi risklərin sığortalanması nəzərdə tutulmadıqda
                    hadisənin baş verməsinin hərbi əməliyyatlar və ya hərbi xarakterli tədbirlər hesab edilən halların
                    nəticəsi olması;
                    <br />
                    <br />
                    - Sığortalının sığortalanmış əmlaka dəyən zərərin qarşısını almaq və ya həcmini azaltmaq üçün lazımi
                    və mümkün tədbirləri görmək iqtidarında olduğu halda, həmin tədbirləri qəsdən görməməsi bu zaman
                    sığorta ödənişindən o həcmdə imtina edilə bilər ki, sığortalı mümkün tədbirləri görmüş olsaydı,
                    zərərin miqdarı həmin həcmdə azalmış olardı;
                    <br />
                    <br />
                    - Sığortalının sığorta predmeti, həmçinin sığorta olunan şəxs və (və ya) sığorta hadisəsi barəsində
                    sığortaçıya qəsdən yanlış məlumat verməsi nəticəsində sığortaçının sığorta riskini qiymətləndirmək,
                    həmçinin sığorta hadisəsinin səbəblərini və (və ya) dəyən zərərin həcmini müəyyənləşdirmək imkanından
                    tam və ya qismən məhrum olması;
                    <br />
                    <br />
                    - Əmlakın sığortası üzrə sığortalı, sığorta olunan və ya faydalanan şəxsin zərərin əvəzini tam olaraq
                    zərər dəyməsində təqsirli olan şəxsdən alması; bu zaman zərərvuran zərərin əvəzini qismən ödəmiş
                    olduqda sığorta ödənişindən ödənilmiş məbləğ həcmində imtina edilir;
                    <br />
                    <br />
                    - Baş vermiş hadisənin icbari sığorta qanunlarına və ya sığorta müqaviləsinə görə sığorta hadisəsi
                    hesab edilməməsi;
                    <br />
                    <br />
                    - Sığorta haqqının növbəti hissəsinin müqavilədə nəzərdə tutulmuş ödənilməsi müddəti başa çatdıqdan 15
                  gün sonra;
                  <br />
                  <br />
                  - İcbari sığorta qanunlarına görə nəzərdə tutulmuş digər hallarda.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section></template>
  