<template>
  <div v-if="loading" class="spinner-div">
    <div class="spinner" ></div>
  </div>
  <div v-if="!loading">
    <div class="pb-2  col-xs-12 col-sm-12 col-md-12">
      <p class="h4 text-main  text-success check-info-head">
        Sizin şəhadətnaməniz uğurla buraxıldı! 
        <br/>
        Ödəniş edib şəhadətnaməni aktivləşdirin.
      </p>
    </div>
    {{ premiumAzn }}
    <div v-if="data" class=" col-xs-12 col-sm-12 col-md-12">
      <div v-for="(d, index) in data" :key="index" class="row check-info-content-body">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 py-2">
          <div class="check-info-content-body-head">Sığorta olunan</div>
          <div class="check-info-content-body-text">
            {{ d.insuredPerson }}
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 py-2">
          <div class="check-info-content-body-head"> Müqavilə nömrəsi</div>
          <div id="contract" class="check-info-content-body-text">
            {{ d.policyNumber }}
            <img src="@/assets/images/ins-copy.svg" @click="copyText('contract')" class="cursor-pointer" />
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 py-2">
          <div class="check-info-content-body-head">Sığorta haqqı</div>
          <div class="check-info-content-body-text">
            {{ d.premiumAzn}}
            <span>AZN</span>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 py-2 btn-payment text-end">
          <a href="https://www.e-pul.az/epay/az/category/insurance/qala_insurance" target="_blank" class="btn-contract cursor-pointer d-inline-block ms-3">
            Ödəniş et
          </a>
        </div>
      </div>

      <div v-if=homeURL class="row mt-5">
        <div class="d-flex justify-content-end pe-0">
          <a @click.prevent="goCabinet" class="btn-main btn-default cursor-pointer d-inline-block ms-3">
            Kabinetə keçid et
          </a>
        </div>
      </div>


    </div>
  </div>
</template>

<script>

import authService from "@/services/auth.service";

export default {
  props: ["loading", "data"],
  data: () => ({
    homeURL: null,
  }),
  mounted() {
    this.homeURL = this.$route.path.startsWith("/travel");

  },
  methods: {
    back() {
      this.$emit("back");
    },
    copyText(id) {
      var copyText = document.getElementById(id).textContent;
      navigator.clipboard.writeText(copyText);
    },
    goCabinet() {
      if (authService.isAuthenticated()) {
        this.$router.push("/profile");
      } else {
        this.emitter.emit("showLoginModal");
      }
    },
  },
};
</script>


