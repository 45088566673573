<template>
  <div class="container my-5 pt-5">
    <div class="row align-items-center">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
        <p class="banner-content h1 text-main">
          <span class="text-blue">Səfər </span> Sığortası
        </p>
        <p class="banner-text mt-3">
          Xaricdə gözlənilməz müalicə xərçləri ilə üzləşməmək və həmçinin viza almaq üçün zəruri olan Səfər sığortanızı rahat, onlayn, bir necə dəqiqə ərzində əldə edə bilərsiz.
        </p>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 d-flex justify-content-end">
        <div class="travel-banner-img">
          <img src="@/assets/images/travel_insurance.png" class="w-100" />
        </div>
      </div>
    </div>
  </div>

  <section id="cmtpl-insurance-form-section travel">
    <div class="container overflow-hidden">
      <div class="tab-content">
        <div class="tab-pane fade show active d-flex justify-content-between  cmplt-tab-pane" id="isb-frame-custom">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 mt-5">
            <div class="row">
              <div class="
                  col-lg-12
                  tab-content
                  pb-5
                  pt-0
                  text-justify
                ">
                <div id="cmtpl-insurance-form" class="cmtpl-insurance-form insurance-form tab-pane"
                  v-bind:class="{ active: formState == 0 }">
                  <request-form :country="requestData.country"
                    :currencies="requestData.currencies" :periods="requestData.periods"
                    v-on:success="requestFormOnSubmitHandler" :loading="isLoading" :loader="isLoader" />
                </div>

                <div id="calculate" class="tab-pane" v-bind:class="{ active: formState == 1 }">
                  <calculate :premium="premium" :data="data" v-on:buyIns="calculateInsTravel" v-on:back="formState = 0" />
                </div>

                <div id="ins-form" class="tab-pane" v-bind:class="{ active: formState == 2 }">
                  <ins-form :data="data" v-on:confirm="requestInsForm" ref="insForm" v-on:back="formState = 1" />
                </div>

                <div id="check-info" class="tab-pane" :class="{ active: formState === 3 }">
                  <check-info :loading="isLoading"  :data="data" @submit="travelRequestHandler" ref="checkInfoForm" @back="formState = 2" :premium="premium" />
                </div>

                <div id="payment" class="tab-pane" v-bind:class="{ active: formState == 4 }">
                  <payment :data="contractData" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 mt-5 ps-3">
            <div class="cmtpl-insurance-icons-container ">
              <ul class="nav mx-auto pe-0 position-relative">
                <li class="position-relative cmplt-nav-items">
                  <a class="
                          insurance-form-icon
                          travel-form-icon
                          cursor-pointer
                          nav-link
                          mx-auto
                          text-center
                          position-relative
                          col-xs-2 col-sm-2 col-md-12 
                        " data-bs-toggle="pill" href="#cmtpl-insurance-form" v-bind:class="{ active: formState == 0 }">
                    <div class="d-flex align-items-center">
                      <div class="ins-icon">
                        <img v-if="formState == 0" src="@/assets/images/ins_step1.svg" />
                        <img v-else src="@/assets/images/step_confirm.svg" />
                      </div>
                      <div class="ms-3 cmplt-nav-item-text">Əsas məlumatları daxil et</div>
                    </div>
                    <div class="ins-icon-hr">
                      <img src="@/assets/images/ins_icons_hr.svg" />
                    </div>
                  </a>
                  <a class="
                          insurance-form-icon
                          travel-form-icon
                          cursor-pointer
                          nav-link
                          mx-auto
                          text-center
                          position-relative
                          col-xs-2 col-sm-2 col-md-12 
                        " data-bs-toggle="pill" href="#calculate" v-bind:class="{
                          active: formState == 1,
                          disabled: formState < 1,
                        }">
                    <div class="d-flex align-items-center">
                      <div class="ins-icon">
                        <img v-if="formState == 0" src="@/assets/images/travel_step2.svg" />
                        <img v-else-if="formState == 1" src="@/assets/images/travel_step2_sucs.svg" />
                        <img v-else src="@/assets/images/step_confirm.svg" />
                      </div>
                      <div v-if="formState == 0" class="ms-3 cmplt-nav-item-text-active">Sığorta haqqını hesabla</div>
                      <div v-else class="ms-3 cmplt-nav-item-text">Sığorta haqqını hesabla</div>
                    </div>
                    <div class="ins-icon-hr">
                      <img src="@/assets/images/ins_icons_hr.svg" />
                    </div>
                  </a>
                  <a class="
                          insurance-form-icon
                          travel-form-icon
                          cursor-pointer
                          nav-link
                          mx-auto
                          text-center
                          position-relative
                          col-xs-2 col-sm-2 col-md-12 
                        " data-bs-toggle="pill" href="#ins-form" v-bind:class="{
                          active: formState == 2,
                          disabled: formState < 2,
                        }">
                    <div class="d-flex align-items-center">
                      <div class="ins-icon">
                        <img v-if="formState < 2" src="@/assets/images/travel_step3.svg" />
                        <img v-else-if="formState == 2" src="@/assets/images/travel_step3_sucs.svg" />
                        <img v-else src="@/assets/images/step_confirm.svg" />
                      </div>
                      <div v-if="formState < 2" class="ms-3 cmplt-nav-item-text-active">Sığortalılar haqqında <br> məlumatı daxil et</div>
                      <div v-else class="ms-3 cmplt-nav-item-text">Sığortalılar haqqında <br> məlumatı daxil et</div>
                    </div>
                    <div class="ins-icon-hr">
                      <img src="@/assets/images/ins_icons_hr.svg" />
                    </div>
                  </a>
                  <a class="
                          insurance-form-icon
                          travel-form-icon
                          cursor-pointer
                          nav-link
                          mx-auto
                          text-center
                          position-relative
                          col-xs-2 col-sm-2 col-md-12 
                        " data-bs-toggle="pill" href="#check-info" v-bind:class="{
                          active: formState == 3,
                          disabled: formState < 3,
                        }">
                    <div class="d-flex align-items-center">
                      <div class="ins-icon">
                        <img v-if="formState < 3" src="@/assets/images/ins_step3.svg" />
                        <img v-else-if="formState == 3" src="@/assets/images/ins_step3_success.svg" />
                        <img v-else src="@/assets/images/step_confirm.svg" />
                      </div>
                      <div v-if="formState < 3" class="ms-3 cmplt-nav-item-text-active">Məlumatı yoxla</div>
                      <div v-else class="ms-3 cmplt-nav-item-text">Məlumatı yoxla</div>
                    </div>
                    <div class="ins-icon-hr">
                      <img src="@/assets/images/ins_icons_hr.svg" />
                    </div>
                  </a>
                  <a class="
                          insurance-form-icon
                          travel-form-icon
                          cursor-pointer
                          nav-link
                          mx-auto
                          text-center
                          position-relative
                          col-xs-2 col-sm-2 col-md-12 
                        " href="#payment" v-bind:class="{
                          active: formState == 4,
                          disabled: formState < 4,
                        }" data-bs-toggle="pill">
                    <div class="d-flex align-items-center">
                      <div class="ins-icon">
                        <img v-if="formState < 4" src="@/assets/images/ins_step4.svg" />
                        <img v-else-if="formState == 4" src="@/assets/images/ins_step4_success.svg" />
                        <img v-else src="@/assets/images/step_confirm.svg" />
                      </div>
                      <div v-if="formState < 4" class="ms-3 cmplt-nav-item-text-active">Ödəniş et</div>
                      <div v-else class="ms-3 cmplt-nav-item-text">Ödəniş et</div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <error-modal ref="errorModal" />
  <banner v-if="formState == 0" />
</template>

<script>
import Banner from "@/views/travel/Banner.vue";
import RequestForm from "@/views/travel/RequestForm.vue";
import Payment from "@/views/travel/Payment.vue";
import CheckInfo from "@/views/travel/CheckInfo.vue";
import Calculate from "@/views/travel/Calculate.vue";
import InsForm from "@/views/travel/InsForm.vue";
import ErrorModal from './ErrorModal.vue';

import api from "@/api/travel.api";

export default {
  components: { Banner, RequestForm, Payment, CheckInfo, Calculate, InsForm ,ErrorModal},
  data: () => ({
    isLoader:false,
    isLoading: false,
    formState: 0,
    calculateForm: {},
    insForm: {
      insuredPersons: []
    },
    requestData: {
      country: null,
      currencies: null,
      periods: null
    },
    data: null,
    premium: 0,
    amount: null,
    purpose: null,
    insPersons: [],
    personData: [],
    contractData: {},
    premiumAzn:0
  }),
  mounted() {
    this.getPeriods();
    this.getCountries();
    this.getCurrencies();
  },

  methods: {
    requestFormOnSubmitHandler(form) {
      this.calculateForm = form;
      this.isLoading = true;
      this.insForm.insuredPersons=[]
      this.premium=0;
      api.calculateTrvlIns(form).then((data) => {
        this.data = data;
        if (data.insuredPersons.every(item => item.isSuccess === true)) {
          for (let i = 0; i < data.insuredPersons.length; i++) {
              this.premium += data.insuredPersons[i].premiumAzn;
              this.insForm.insuredPersons.push({
                  birthDate: data.insuredPersons[i].birthDate,
                  premium: data.insuredPersons[i].premiumAzn,
                  firstName: null,
                  lastName: null,
                  passportNumber: null,
              });
          }
          this.formState = this.formState + 1;
        } else {
          this.$refs["errorModal"].show();
        }
        
      }).catch((error) => {
        this.emitter.emit("error", error);
      }).finally(()=>{
        this.isLoading = false;
      });
    },
    calculateInsTravel(data) {
      this.form = data;
      this.formState = 2;
      this.$refs.insForm.setData(this.insForm.insuredPersons);
    },

    requestInsForm(data) {
      this.insForm=data;
      this.$refs.checkInfoForm.setData(this.insForm.insuredPersons);
      this.formState = 3;
    },

    travelRequestHandler() {
      this.isLoading = true;
      let requestData = {
        userId: 0,
        startDate: this.calculateForm.startDate,
        endDate: this.calculateForm.endDate,
        countryId: this.calculateForm.countryId,
        currencyId: this.calculateForm.currencyId,
        periodId: this.calculateForm.periodId,
        amount: this.calculateForm.amount,
        purpose: this.calculateForm.purpose,
        covidRiskAvailable: this.calculateForm.covidRiskAvailable,
        covidCoverage: this.calculateForm.covidCoverage,
        insuredPersons: this.insForm.insuredPersons,
        phoneNumber:this.insForm.phoneNumber
      }

      api.requestTravel(requestData).then((response) => {
        this.contractData=response;
        this.formState = 4;
      }).catch((error) => {
        this.emitter.emit("error", error);
      }).finally(()=>{
        this.isLoading = false;
      });;
    },

    getCountries() {
      this.isLoader = true;
      api.getCountries().then((data) => {
        this.requestData.country = data;
      }).catch((error) => {
        this.emitter.emit("error", error);
      }).finally(()=>{
        this.isLoader = false;
      });;
    },
    getCurrencies() {
      this.isLoader = true;
      api.getCurrencies().then((data) => {
        this.requestData.currencies = data;
      }).catch((error) => {
        this.emitter.emit("error", error);
      })
      .finally(()=>{
        this.isLoader = false;
      });;
    },
    getPeriods() {
      this.isLoader = true;
      api.getPeriods().then((data) => {
        this.requestData.periods = data;
      }).catch((error) => {
        this.emitter.emit("error", error);
      }).finally(()=>{
        this.isLoader = false;
      });;
    },
  },
  
};
</script>