<template>
  <div v-if="loader" class="spinner-div">
    <div class="spinner"></div>
  </div>
  <div v-else class="row">
    <p class="h4 initial-form-text">
      Sığorta haqqının hesablanması üçün məlumatlarınızı daxil edin.
    </p>
    <form @submit.prevent="submitHandler">
      <div class="row">
        <div class="col-lg-6">
          <div
            class="form-group position-relative mt-3"
            :class="{ error: v$.form.countryId.$errors.length }"
          >
            <label
              :class="{ full: form.countryId }"
              class="input-label small mb-2"
              for="country"
            >
              Səfər edilən ölkə
            </label>
            <select
              :class="{ 'has-value': form.countryId }"
              v-model="form.countryId"
              id="country"
              name="country"
              class="form-select init-form-select input-default font-size-default px-4"
            >
              <option disabled selected :value="null">Səfər edilən ölkə</option>
              <option v-for="c in country" :key="c.id" :value="c.id">
                {{ c.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-lg-6">
          <div
            class="form-group position-relative mt-3"
            :class="{ error: v$.form.purpose.$errors.length }"
          >
            <label
              :class="{ full: form.purpose }"
              class="input-label small mb-2"
              for="purpose"
            >
              Səfərin məqsədi
            </label>
            <select
              v-model="form.purpose"
              :class="{ 'has-value': form.purpose }"
              class="form-select init-form-select input-default font-size-default"
            >
              <option disabled selected :value="null">Sığorta məqsədi</option>
              <option
                v-for="p in purpose"
                v-bind:key="p.key"
                :value="p.key"
                :selected="selectedOption === p.value"
              >
                {{ p.value }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-lg-6 col-md-12 col-sm-12">
          <div class="d-flex align-items-center mt-4">
            <div class="col-xxl-9 col-xl-8 col-md-8 col-sm-8 col-8">
              <div
                class="form-group"
                :class="{ error: v$.form.amount.$errors.length }"
              >
                <label
                  :class="{ full: form.amount }"
                  class="input-label small mb-2"
                  for="money"
                  
                >
                  Sığorta məbləği
                </label>
                <select
                  v-model="form.amount"
                  :class="{ 'has-value': form.amount }"
                  class="form-select init-form-select input-default font-size-default"
                  :placeholder="'Sığorta məbləği'"
                >
                  <option disabled selected :value="null">
                    Sığorta məbləği
                  </option>
                  <option  v-for="a in amount" v-bind:key="a.key" :value="a.key" :disabled="shouldDisableAmountOption(a.key)">
                    {{ a.value }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-xxl-3 col-xl-4 col-md-4 col-sm-4 col-4 ms-2">
              <div
                class="form-group position-relative"
                :class="{ error: v$.form.currencyId.$errors.length }"
              >
                <label
                  :class="{ full: form.currencyId }"
                  class="input-label small mb-2"
                  for="money"
                >
                  Valyuta
                </label>
                <select
                  v-model="form.currencyId"
                  :class="{ 'has-value': form.currencyId }"
                  id="currencies"
                  name="currencies"
                  class="form-select init-form-select input-default font-size-default"
                >
                  <option :selected="shouldDisableCurrencyOption(c.id) ? 6 : c.id" v-for="c in currencies" :key="c.id" :value="c.id" :disabled="shouldDisableCurrencyOption(c.id)">
                    {{  c.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group position-relative mt-4 mb-3">
            <label
              :class="{ full: form.covidCoverage }"
              class="input-label small mb-2"
              for="money"
            >
              Covid təminat
            </label>
            <select
              :disabled="!form.covidRiskAvailable"
              v-model="form.covidCoverage"
              :class="{ 'has-value': form.covidCoverage }"
              class="form-select init-form-select input-default font-size-default"
              :placeholder="'Covid təminat'"
            >
              <option disabled selected :value="null">Covid təminat</option>
              <option
                v-for="c in covidCoverage"
                v-bind:key="c.key"
                :value="c.key"
              >
                {{ c.value }}
              </option>
            </select>
          </div>
          <div class="d-flex">
            <label class="d-flex align-items-center">
              <input
                type="radio"
                name="check"
                v-model="form.covidRiskAvailable"
                :value="true"
                @click="form.covidRiskAvailable = true"
              />
              <span class="ms-2"> Var</span>
            </label>
            <label class="ms-4 d-flex align-items-center">
              <input
                type="radio"
                name="check"
                v-model="form.covidRiskAvailable"
                :value="false"
                :checked="!form.covidRiskAvailable"
                @click="availableCovid"
              />
              <span class="ms-2"> Yoxdur</span>
            </label>
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-lg-6">
          <label class="my-2 input-label full small"
            >Sığorta olunanların doğum tarixləri</label
          >
          <div v-for="(child, index) in form.birthDates" :key="index">
            <form-input
              v-model="form.birthDates[index]"
              :cssClass="{ error: v$.form.birthDates.$errors.length }"
            ></form-input>
          </div>
          <div class="row justify-content-end mt-3">
            <div class="col-sm-5 d-flex justify-content-end align-items-center">
              <div class="qty-div">
                <div class="minus" @click="remove">
                  <img src="@/assets/images/count_minus.svg" />
                </div>
                <span class="count">{{ form.birthDates.length }}</span>
                <div class="plus" @click="add">
                  <img src="@/assets/images/count_plus.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group position-relative mt-2 mb-2">
            <label class="small input-label full">Səfər müddəti</label>
            <div class="travel-duration mt-2">
              <div
                :style="{
                  justifyContent: this.$route.path.startsWith('profile')
                    ? 'space-between'
                    : 'initial',
                }"
                class="row travel-duration-items select-dates mt-2 pe-0"
              >
                <div
                  v-for="(p, index) in periods"
                  :key="index"
                  class="col-lg-2 col-lg-3 select-date mb-2"
                  :class="[{ select: index == 0 }, `select-${p.id}`]"
                >
                  <input
                    type="radio"
                    :id="p.id"
                    :value="p.id"
                    class="period-radio"
                  />
                  <div @click="calc(p.totalDays, p.insuredDays, p.id)">
                    {{ p.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div
              class="form-group position-relative col-xl-12 col-xxl-6 my-2"
              :class="{ error: v$.form.startDate.$errors.length }"
            >
              <label
                :class="{ full: form.startDate }"
                class="input-label small mb-2"
              >
                Başlama tarixi
              </label>
              <input
                :min="minDate"
                type="date"
                :class="{ 'has-value': form.startDate }"
                class="form-control start"
                v-model="form.startDate"
                @change="calculateDays"
              />
            </div>
            <div
              class="form-group position-relative col-xl-12 col-xxl-6 my-2"
              :class="{ error: v$.form.endDate.$errors.length }"
            >
              <label
                :class="{ full: form.endDate }"
                class="input-label small mb-2"
              >
                Bitmə tarixi
              </label>
              <input
                :min="minEndDate"
                :disabled="form.periodId != 1"
                :class="{ 'has-value': form.endDate }"
                type="date"
                class="form-control end"
                v-model="form.endDate"
                @change="calculateDays"
              />
            </div>
          </div>
          <div class="d-flex justify-content-end mt-2">
            <div class="day-number">
              <span>{{ days }} gün</span>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col d-flex justify-content-end">
          <a
            @click="cancel"
            class="btn btn-default btn-hover d-inline-block text-decoration-none"
          >
            Imtina et
          </a>
          <button
            type="submit"
            class="btn-main btn-default btn-hover cursor-pointer d-inline-block ms-3"
            :disabled="loading"
          >
            <span class="spinner-border spinner-border-sm" v-if="loading" />
            Hesabla
          </button>
        </div>
      </div>
    </form>
  </div>
</template>
  
<script>
import { maska } from "maska";
import { helpers, required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { Purpose } from "@/enums";
import { Amount } from "@/enums"
import { CovidCoverage } from "@/enums"
import FormInput from "@/views/travel/FormInput.vue";

export default {
  props: ["loading", "country", "currencies", "periods","loader"],
  directives: { maska },
  components: { FormInput },
  data: () => ({
    form: { 
      birthDates: [null],
      startDate: null,
      endDate: null,
      countryId: null,
      currencyId: null,
      periodId: 1,
      amount: null,
      purpose: null,
      covidRiskAvailable: false,
      covidCoverage: null
     },
    days: 0,
    totalDays:0,
    type: 'text',
    selectedOption: 1,
  }),
  mounted () {
    const params = this.$route.query;
    this.form.startDate = params.startDate;
    this.form.endDate = params.endDate;
    this.form.countryId = params.countryId;
    this.days=params.days
  },
  computed: {
    justifyContentValue () {
        this.$route.path.startsWith('profile') ? '' : 'start'
    },
    minDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    minEndDate() {
      const startDate = new Date(this.form.startDate);
      startDate.setDate(startDate.getDate() + 3);
      const year = startDate.getFullYear();
      const month = String(startDate.getMonth() + 1).padStart(2, '0');
      const day = String(startDate.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    },
    maxDate() {
      const endDate = new Date(this.form.endDate);
      endDate.setDate(endDate.getDate() - 3);

      const year = endDate.getFullYear();
      const month = String(endDate.getMonth() + 1).padStart(2, '0');
      const day = String(endDate.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    },
    purpose() { return Purpose },
    amount() { return Amount },
    covidCoverage() { return CovidCoverage },
    periodsBoolean(){
      return this.periods
    }
  },
  validations: () => ({
    form: { currencyId: { required },
    countryId:{required},
    amount: { required },
    purpose: { required },
    startDate:{required},
    endDate:{required},
    birthDates:{
      $each: helpers.forEach({
        required      
      })
    }},
  }),
  setup: () => ({ v$: useVuelidate() }),
  watch: {
    selectedOption(newValue, oldValue){
        console.log(newValue);
          this.selectedOption = newValue;
    },
    'form.covidCoverage'(newCovidCoverage) {
      if (newCovidCoverage === 3) {
        this.form.currencyId = 6;
        this.form.amount=1
      }
    }
  },
  methods: {
    clear() {
      this.form = { 
        birthDates: [null],
        startDate: null,
        endDate: null,
        countryId: null,
        currencyId: null,
        periodId: 1,
        amount: null,
        purpose: null,
        covidRiskAvailable: false,
        covidCoverage: null
      };
    },


    shouldDisableAmountOption(amountKey) {
      return this.form.covidCoverage == 3 && amountKey !== 1;
    },

    shouldDisableCurrencyOption(currencyKey) {
      return this.form.covidCoverage == 3 && currencyKey !== 6;
    },

    validateDate() {
      const today = new Date().toISOString().split('T')[0];

      if (this.selectedDate < today) {
        this.selectedDate = '';
      }
    },
    async submitHandler() {
      const result = await this.v$.$validate();
      if (result) {
        this.$emit("success", this.form);
      }
    },
    cancel() {
      if (!this.$route.path.startsWith("travel")) {
        this.clear();
        if(document.querySelector(".select-date.select")){
          document.querySelector(".select-date.select").classList.remove("select");
        }
        document.querySelector(".select-1").classList.add("select");
      }
    },

    calculateDays() {
     if (this.form.startDate && this.form.endDate) {
      if (this.form.periodId==1) {
        const start = new Date(this.form.startDate);
        const end = new Date(this.form.endDate);
        const diffInMilliseconds = Math.abs(end - start);
        const diffInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24));
        this.days = diffInDays;
      }
      else{
        const today = new Date(this.form.startDate)
        const ends = new Date(today.setDate(today.getDate() + this.totalDays));
        this.form.endDate= ends.toISOString().split('T')[0];
      }
     }
    },

    calc(day,insuredDays,id){
      this.form.periodId=id;
      this.totalDays=day;

      const today = new Date(); 
      const tomorrow = new Date(today.setDate(today.getDate()));
      this.form.startDate= tomorrow.toISOString().split('T')[0];

      if(this.form.periodId!=1){
        this.days=insuredDays;
        const ends = new Date(today.setDate(today.getDate() + day));
        this.form.endDate= ends.toISOString().split('T')[0];
      }else{
        this.form.endDate=null;
      }

      if(document.querySelector(".select-date.select")){
        document.querySelector(".select-date.select").classList.remove("select");
      }
      document.querySelector(`.select-${id}`).classList.add(`select`);
      
    },

    
    add() {
      this.form.birthDates.push(null);
    },
    remove() {
      if (this.form.birthDates.length>1) {
        this.form.birthDates.splice(this.form.birthDates.length-1, 1);
      }
    },
    availableCovid(){
      this.form.covidRiskAvailable = false;
      this.form.covidCoverage=null
    }
  },
};
</script>